import { DatePipe } from "@angular/common";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom, isDevMode, provideExperimentalZonelessChangeDetection } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { provideServiceWorker } from '@angular/service-worker';
import { appRoutes } from "./app.routes";
import { httpInterceptorService } from "./shared/utilities/http-interceptor.service";

const getBaseUrl = () => document.getElementsByTagName('base')[0].href;

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([httpInterceptorService])
    ),
    provideRouter(
      appRoutes,
      withComponentInputBinding(),
      // withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom([BrowserAnimationsModule]),
    DatePipe,
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ]
}
