import { Component, computed, inject, signal } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { MaterialModule } from 'src/app/material.module';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  imports: [
    MaterialModule,
  ]
})
export class FooterComponent {
  private loginService: LoginService = inject(LoginService);
  public readonly profileData = this.loginService.profileData.asReadonly();
  companyLogoUrl = computed(() => {
    return `${this.loginService.assetUrl}/${encodeURIComponent(this.profileData()?.companyLogo || '')}`;
  });

  showCompanyName = signal<boolean>(false);

  handleMissingImage = () => {
    this.showCompanyName.set(true);
  }
}
