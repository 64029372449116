import { Routes } from '@angular/router';
import { routes as adminRoutes } from './admin/admin.routes';
import { routes as clientRoutes } from './client/client.routes';
import { ADMIN_ROLE, APPLICATION_BROWSER_TITLE, SPECIAL_ROLE } from './GlobalConstants';
import { LoginGuard } from './login/login.guard';
import { stringMerge } from './shared/utilities/helper';

export const appRoutes: Routes = [
  { path: '', redirectTo: 'client', pathMatch: 'full' },
  {
    path: 'client',
    canActivate: [LoginGuard],
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Web'),
    children: clientRoutes,
  },
  {
    path: 'admin',
    canActivate: [LoginGuard],
    data: { roles: [ADMIN_ROLE, SPECIAL_ROLE] },
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Admin'),
    children: adminRoutes,
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then(l => l.LoginComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Login'),
  },
  {
    path: 'changes',
    loadComponent: () => import('./generic/change-log/change-log.component').then(cl => cl.ChangeLogComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Changelog'),
  },
  {
    path: '**',
    loadComponent: () => import('./generic/page-not-found/page-not-found.component').then(pnf => pnf.PageNotFoundComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Page Not Found'),
  },
];
