import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { Login } from 'src/app/login/login.model';
import { LoginService } from 'src/app/login/login.service';
import { GENERIC_API_ERROR, INACTIVE_ACCOUNT, INVALID_LOGIN_ENTERED, INVALID_PASSWORD_ENTERED, SERVER_DOWN, SESSION_ACCOUNT_EXPIRED } from 'src/app/MessageConstants';
import { LOGIN_URL } from 'src/app/RouteConstants';
declare let gtag: Function;

export function httpInterceptorService(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const router: Router = inject(Router);
  const loginService: LoginService = inject(LoginService);
  const redirectToLogin = () => {
    console.error('Received HTML response, possibly server is down.');
    sessionStorage.setItem('loginError', SERVER_DOWN);
    window.location.href = '/login';
  }

  const storedValue: string = localStorage.getItem('loginToken') || '{}';
  const loginToken = () => JSON.parse(storedValue);

  const logError = (error: HttpErrorResponse) => {
    const auth: Login = loginToken();
    gtag('event', `Error: ${error.status}`, {
      event_category: 'API Error',
      event_label: error.message,
      value: auth?.profile?.id
    });
  }

  const clonedRequest = req.clone({
    setHeaders: {
      'Authorization': 'bearer ' + loginToken().token,
      'Accept': 'application/json'
    }
  });

  if (!(req.body instanceof FormData)) {
    clonedRequest.headers.set('Content-Type', 'application/json');
  }

  if (sessionStorage.getItem('loginError') === SERVER_DOWN) {
    redirectToLogin();
  }

  return next(clonedRequest).pipe(
    catchError((error: HttpErrorResponse) => {
      logError(error);
      const errorInfo = error.error;

      if (error.status === 401) {
        loginService.logout();
        const errorUrl = error.url || '';
        if (errorUrl.indexOf(LOGIN_URL) >= 0) {
          sessionStorage.setItem('loginError', INVALID_LOGIN_ENTERED);
        } else {
          sessionStorage.setItem('loginError', SESSION_ACCOUNT_EXPIRED);
        }
        router.navigateByUrl(LOGIN_URL);
      } else if (typeof errorInfo.text === 'string' && /<[^>]*>/.test(errorInfo.text)) {
        redirectToLogin();
      } else if (errorInfo instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', errorInfo.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(`Backend returned code ${error.status}, body was`, errorInfo);

        if (errorInfo && typeof (errorInfo) === 'object' && Object.keys(errorInfo).length > 0) {
          if (errorInfo.Login) {
            const errorMessage = errorInfo.Login.pop();
            if (errorMessage === 'Invalid password') {
              sessionStorage.setItem('loginError', INVALID_PASSWORD_ENTERED);
              return throwError(() => new Error(INVALID_PASSWORD_ENTERED));
            }
            if (errorMessage === 'Account inactive') {
              sessionStorage.setItem('loginError', INACTIVE_ACCOUNT);
              return throwError(() => new Error(INACTIVE_ACCOUNT));
            }
            if (errorMessage === 'Invalid login') {
              sessionStorage.setItem('loginError', INVALID_LOGIN_ENTERED);
              return throwError(() => new Error(INVALID_LOGIN_ENTERED));
            }
          } else if (errorInfo.Job) {
            const errorCode = errorInfo.Job.pop();
            return throwError(() => new Error(errorCode));
          } else if (errorInfo.File) {
            const errorMessage = errorInfo.File.pop();
            return throwError(() => new Error(errorMessage));
          }
        }
      }
      return throwError(() => new Error(GENERIC_API_ERROR));
    })
  );
}
