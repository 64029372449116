import { Routes } from "@angular/router";
import { APPLICATION_BROWSER_TITLE } from "../GlobalConstants";
import { stringMerge } from "../shared/utilities/helper";

export const routes: Routes = [
  {
    path: 'company',
    children: [
      {
        path: '',
        loadComponent: () => import('./company/company.component').then(cm => cm.CompanyComponent),
        pathMatch: 'full',
      },
      {
        path: ':companyId',
        children: [
          {
            path: 'department',
            loadComponent: () => import('./department/department.component').then(dm => dm.DepartmentComponent),
            title: stringMerge(APPLICATION_BROWSER_TITLE, 'Departments'),
          },
          {
            path: 'employee',
            loadComponent: () => import('./employee/employee.component').then(em => em.EmployeeComponent),
            title: stringMerge(APPLICATION_BROWSER_TITLE, 'Employees'),
          },
          {
            path: 'job',
            children: [
              {
                path: '',
                loadComponent: () => import('./job/job.component').then(jm => jm.JobComponent),
                title: stringMerge(APPLICATION_BROWSER_TITLE, 'Jobs'),
                pathMatch: 'full',
              },
              {
                path: 'change-approver',
                loadComponent: () => import('./job/change-approver/change-approver.component').then(ca => ca.ChangeApproverComponent),
                title: stringMerge(APPLICATION_BROWSER_TITLE, 'Change Approver'),
              }
            ],
          },
          {
            path: 'cost-code',
            loadComponent: () => import('./cost-code/cost-code.component').then(cc => cc.CostCodeComponent),
            title: stringMerge(APPLICATION_BROWSER_TITLE, 'Cost Codes'),
          },
        ],
      },
    ]
  }
];
