import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SNACK_BAR_DISMISS_BUTTON, SNACK_BAR_DURATION } from '../GlobalConstants';
import { GENERIC_ROLE_ERROR } from '../MessageConstants';
import { APP_URL, LOGIN_URL } from '../RouteConstants';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})

export class LoginGuard {
  private router = inject(Router);
  private loginService = inject(LoginService);
  private snackBar = inject(MatSnackBar);

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userData = this.loginService.loginData.asReadonly();

    if (userData()?.token) {
      if (route.data['roles'] && route.data['roles'].indexOf(userData()?.role) === -1) {
        this.router.navigateByUrl(APP_URL);
        this.snackBar.open(`Authorization: ${GENERIC_ROLE_ERROR}`, SNACK_BAR_DISMISS_BUTTON, {
          duration: SNACK_BAR_DURATION,
        });
        return false;
      }
      return true;
    }

    this.router.navigateByUrl(LOGIN_URL);
    return false;
  }
}
