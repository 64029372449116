import { Component, OnInit, TemplateRef, effect, inject, viewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { FooterComponent } from './shared/includes/footer/footer.component';
import { NavbarComponent } from './shared/includes/navbar/navbar.component';
import { PwaUpdateService } from './shared/pwa-update.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    FooterComponent,
    MaterialModule,
    NavbarComponent,
    RouterModule,
  ]
})
export class AppComponent implements OnInit {
  private router = inject(Router);
  private pwaService = inject(PwaUpdateService);
  private dialog = inject(MatDialog);
  private isAppUpdateAvailable = this.pwaService.isUpdateAvailable.asReadonly();
  private readonly appUpdater = viewChild<TemplateRef<any>>('appUpdater');

  constructor() {
    effect(() => {
      if (this.isAppUpdateAvailable()) {
        this.promptUser();
      }
    });
  }

  ngOnInit() {
    this.pwaService.monitorUpdates();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      gtag('config', 'UA-171305969-1', { 'page_path': evt.urlAfterRedirects });
    });
  }

  promptUser(): void {
    this.dialog.open(this.appUpdater()!, {
      width: '400px'
    });
  }

  refreshPage(): void {
    window.location.reload();
  }
}
