<nav class="navbar-header" aria-label="Top Toolbar">
  <a routerLink="/" class="navbar-brand-link">
    <svg viewBox="2 0 85 38" class="navbar-brand" xmlns="http://www.w3.org/2000/svg">
      <text class="mat-headline-4" x="1.546" y="25">{{applicationTitle}}</text>
      <text class="description mat-headline-6" x="3.154" y="35">{{applicationDescription}}</text>
    </svg>
  </a>
  <div class="flex-spacer navbar-start"></div>
  @if(profileData()?.id) {
    <a mat-button [matMenuTriggerFor]="reportsMenu" title="Reports" *appAuth="['Admin', 'Manager']">
      <mat-icon>menu_book</mat-icon>
      <span class="hide-menu-label">Reports &rsaquo;</span>
    </a>
    @if(profileData()?.isApprover) {
      <a mat-button routerLinkActive="navbar-header-item-selected" routerLink="/client/my-task" title="My Tasks">
        <mat-icon>task</mat-icon>
        <span class="hide-menu-label">My Tasks</span>
      </a>
    }
    <a mat-button routerLinkActive="navbar-header-item-selected" routerLink="/client/my-request" title="Requests">
      <mat-icon>topic</mat-icon>
      <span class="hide-menu-label">Requests</span>
    </a>

    @if(!location.path().includes('admin') && !location.path().includes('/request/update')) {
      <a mat-button (click)="showNewRequest()" title="New Request">
        <mat-icon>post_add</mat-icon>
        <span class="hide-menu-label">New Request</span>
      </a>
    }
    <div class="flex-spacer"></div>
    <input type="search" placeholder="Search" class="navbar-search" (keyup)="searchHandler($event)" aria-label="Search">
    <a mat-button [matMenuTriggerFor]="usersMenu" class="navbar-profile">
      <mat-icon>assignment_ind</mat-icon>
      <span class="last-menu-label text-overflow">{{profileData()?.name | titlecase}}</span>
    </a>
    <mat-menu #reportsMenu="matMenu">
      <a mat-menu-item routerLink="/client/reports/jobs">
        <mat-icon>folder</mat-icon>
        <span>Requests - Summary</span>
      </a>
      <a mat-menu-item routerLink="/client/reports/materials">
        <mat-icon>description</mat-icon>
        <span>Requests - Itemized</span>
      </a>
      <a mat-menu-item routerLink="/client/reports/assets">
        <mat-icon>inventory</mat-icon>
        <span>Assets</span>
      </a>
      <a mat-menu-item routerLink="/client/reports/items">
        <mat-icon>list</mat-icon>
        <span>Items</span>
      </a>
    </mat-menu>
    <mat-menu #usersMenu="matMenu">
      <button mat-menu-item disabled class="navbar-profile-name">
        <span class="mat-small">{{profileData()?.name | titlecase}}</span>
        <span class="mat-small text-overflow">{{profileData()?.email}}</span>
      </button>
      <mat-divider></mat-divider>
      @if(userData()?.isAdmin || userData()?.isSpecialRole) {
        <a mat-menu-item routerLink="/admin/company" title="Configuration">
          <mat-icon>settings</mat-icon>
          <span>Configuration</span>
        </a>
      }
      <a mat-menu-item routerLink="/client/change-password">
        <mat-icon>lock_reset</mat-icon>
        <span>Change Password</span>
      </a>
      <a mat-menu-item (click)="initiateLogout($event)">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </a>
      <mat-divider></mat-divider>
      <button mat-menu-item class="navbar-app-version" (click)="navigateVersionPage()">
        <span class="mat-small">App Version: {{version}}</span>
      </button>
    </mat-menu>
  } @else {
    <a mat-button routerLinkActive="navbar-header-item-selected" routerLink="/login" title="Login">
      <mat-icon>lock</mat-icon>
      <span>Login</span>
    </a>
  }
</nav>
