import { Directive, effect, inject, input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { AuthPermission } from './auth.model';

@Directive({
  selector: '[appAuth]',
  standalone: true
})
export class AuthDirective {
  public readonly userType = input.required<AuthPermission[]>({
    alias: 'appAuth',
  });
  private readonly userData = inject(LoginService).loginData.asReadonly();
  private templateRef = inject(TemplateRef);
  private viewContainerRef = inject(ViewContainerRef);

  constructor() {
    effect(() => {
      if (this.userType().includes(this.userData()?.role as AuthPermission)) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    });
  }
}
