import { Routes } from "@angular/router";
import { APPLICATION_BROWSER_TITLE } from "../GlobalConstants";
import { LoginGuard } from "../login/login.guard";
import { stringMerge } from "../shared/utilities/helper";
import { routes as reportRoutes } from "./reports/report.routes";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'my-request',
    pathMatch: 'full'
  },
  {
    path: 'request-details/:requestNumber',
    loadComponent: () => import('./request-details/request-detail.component').then(m => m.RequestDetailsComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Request Details'),
  },
  {
    path: 'request',
    children: [
      {
        path: 'create',
        loadComponent: () => import('./create-request/create-request.component').then(c => c.CreateRequestComponent),
        title: stringMerge(APPLICATION_BROWSER_TITLE, 'Create Request'),
      },
      {
        path: 'update/:requestNumber',
        loadComponent: () => import('./create-request/update-request/update-request.component').then(c => c.UpdateRequestComponent),
        title: stringMerge(APPLICATION_BROWSER_TITLE, 'Update Request'),
      },
    ],
  },
  {
    path: 'my-task',
    loadComponent: () => import('./my-task/my-task.component').then(m => m.MyTaskComponent),
    canActivate: [LoginGuard],
    data: { customRoles: ['Approver'] },
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'View Assigned Tasks'),
  },
  {
    path: 'my-request',
    loadComponent: () => import('./my-request/my-request.component').then(m => m.MyRequestComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'View My Requests'),
  },
  {
    path: 'change-password',
    loadComponent: () => import('./change-password/change-password.component').then(m => m.ChangePasswordComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Change Password'),
  },
  {
    path: 'reports',
    children: reportRoutes,
  },
];
