import { Location, TitleCasePipe } from '@angular/common';
import { Component, inject, viewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { isEmpty } from 'lodash';
import { APPLICATION_DESCRIPTION, APPLICATION_TITLE } from 'src/app/GlobalConstants';
import { LOGIN_URL, REQUEST_DETAIL_DIRECT_URL } from 'src/app/RouteConstants';
import { NewRequestDetailComponent } from 'src/app/client/create-request/new-request-detail/new-request-detail.component';
import { LoginService } from 'src/app/login/login.service';
import { MaterialModule } from 'src/app/material.module';
import { stringMerge } from 'src/app/shared/utilities/helper';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../../package.json';
import { AuthDirective } from '../../utilities/auth/auth.directive';
import { PageHeaderService } from '../page-header/page-header.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  imports: [
    MaterialModule,
    TitleCasePipe,
    AuthDirective,
    RouterLink,
    RouterLinkActive
  ]
})
export class NavbarComponent {
  private dialog = inject(MatDialog);
  private pageService = inject(PageHeaderService);

  private loginService: LoginService = inject(LoginService);
  private router = inject(Router);
  public location = inject(Location);

  readonly sidenav = viewChild<MatSidenav>('sidenav');
  public readonly profileData = this.loginService.profileData.asReadonly();
  public readonly userData = this.loginService.loginData.asReadonly();

  applicationTitle = APPLICATION_TITLE;
  applicationDescription = APPLICATION_DESCRIPTION;
  private packageTag = isEmpty(environment.packageTag) ? '' : `-${environment.packageTag}`;
  public version = packageJson.version + this.packageTag;

  clickHandler() {
    this.sidenav()?.close();
  }

  searchHandler = (event: KeyboardEvent) => {
    const target = event.target as HTMLInputElement;
    const inputValue = target?.value.trim();
    if (inputValue !== '' && event.key === 'Enter') {
      this.pageService.gLog({
        type: 'event',
        action: 'Search',
        category: 'Nav Bar',
        label: this.userData()?.role || '',
        value: this.profileData()?.id,
      });
      target.value = '';
      const requestDetailsUrl = stringMerge(REQUEST_DETAIL_DIRECT_URL, inputValue);
      this.router.navigateByUrl(requestDetailsUrl);
      target.value = '';
    }
  }

  initiateLogout(event: Event) {
    this.pageService.gLog({
      type: 'event',
      action: 'click',
      category: 'Logout',
      label: this.userData()?.role || '',
      value: this.profileData()?.id,
    });
    event.preventDefault();
    this.loginService.logout();
    this.router.navigateByUrl(LOGIN_URL);
  }

  showNewRequest() {
    this.dialog.open(NewRequestDetailComponent, {
      width: '400px',
      minWidth: '300px'
    });
  }

  navigateVersionPage() {
    this.router.navigateByUrl('/changes');
  }
}
