<mat-divider></mat-divider>
<div class="footer-card">
  @if (profileData()?.companyLogo && !showCompanyName()) {
    <img [src]="companyLogoUrl()" height="45" [alt]="profileData()?.companyName" (error)="handleMissingImage()" class="footer-logo" />
  } @else {
    <span>{{profileData()?.companyName}}</span>
  }
  <div class="mat-small">
    <a class="footer-link" href="http://www.airmech.net" target="_blank">Airmech</a>
    &copy; 2024
  </div>
</div>
<mat-divider></mat-divider>
