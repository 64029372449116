import { Routes } from "@angular/router";
import { APPLICATION_BROWSER_TITLE } from "src/app/GlobalConstants";
import { stringMerge } from "src/app/shared/utilities/helper";

export const routes: Routes = [
  {
    path: 'assets',
    loadComponent: () => import('./asset-report/asset-report.component').then(m => m.AssetReportComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Assets Report'),
  },
  {
    path: 'materials',
    loadComponent: () => import('./job-material/job-material.component').then(m => m.JobMaterialComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Materials Report'),
  },
  {
    path: 'jobs',
    loadComponent: () => import('./job-request/job-request.component').then(m => m.JobRequestComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Jobs Report'),
  },
  {
    path: 'items',
    loadComponent: () => import('./item-report/item-report.component').then(m => m.ItemReportComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Items Report'),
  }
];
