{
  "name": "client",
  "version": "2.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 5000",
    "build": "ng build",
    "clean": "rm -rf dist",
    "build-prod": "npm run clean && ng build --configuration production",
    "build-beta": "npm run clean && ng build --configuration beta",
    "build-upgrade": "rm -rf dist && npm run clean && ng build --configuration upgrade",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "find-deadcode": "ts-prune"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.0.0",
    "@angular/cdk": "19.0.0",
    "@angular/common": "19.0.0",
    "@angular/compiler": "19.0.0",
    "@angular/core": "19.0.0",
    "@angular/forms": "19.0.0",
    "@angular/material": "19.0.0",
    "@angular/platform-browser": "19.0.0",
    "@angular/platform-browser-dynamic": "19.0.0",
    "@angular/platform-server": "19.0.0",
    "@angular/router": "19.0.0",
    "@angular/service-worker": "19.0.0",
    "exceljs": "4.4.0",
    "file-saver": "2.0.5",
    "rxjs": "7.8.1",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.0.4",
    "@angular-eslint/builder": "18.4.1",
    "@angular-eslint/eslint-plugin": "18.4.1",
    "@angular-eslint/eslint-plugin-template": "18.4.1",
    "@angular-eslint/schematics": "18.4.1",
    "@angular-eslint/template-parser": "18.4.1",
    "@angular/cli": "19.0.0",
    "@angular/compiler-cli": "19.0.0",
    "@types/exceljs": "1.3.2",
    "@types/file-saver": "2.0.7",
    "@types/lodash": "4.17.13",
    "@typescript-eslint/eslint-plugin": "8.15.0",
    "@typescript-eslint/parser": "8.15.0",
    "eslint": "9.15.0",
    "ng-packagr": "19.0.0",
    "ts-prune": "0.10.3",
    "typescript": "5.6.3"
  },
  "optionalDependencies": {
    "ts-node": "10.9.2",
    "tslint": "6.1.3"
  }
}
